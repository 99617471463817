.skill-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    /* border: 1px solid #120136; */
    border-radius: 5px;
    padding: 10px;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
    margin: 10px;
    font-weight: 600;
    width: 150px;
    flex-grow: 1;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.skill-card:hover {
    transform: scale(1.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
