body {
	background-image: url(./background.jpg);
	background-color: #79d70f;
	color: #162447;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
	text-align: center;
	display: flex;
	min-height: 100vh;
	align-items: center;
}

h1 {
	font-size: 57px;
}